import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'react-toastify/dist/ReactToastify.css';
import { CookiesProvider } from 'react-cookie';

if (!!process.env.REACT_APP_SENTRY_ENV) {
  Sentry.init({
    dsn: 'https://a98253f54db74a1c8c3645836129746c@o1369166.ingest.sentry.io/6676874',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    debug: false,
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
