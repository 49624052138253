import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './Loader.module.scss';
import circleLoader from '../../assets/circle_loader.svg';
import { ReactSVG } from 'react-svg';
import marsLogo from '../../assets/logo.svg';

export type Props = {
  children?: ReactNode;
  isLoading: boolean;
  isFullScreen?: boolean;
  isPageLoader?: boolean;
  height?: number;
  className?: string;
};

const Loader = ({ children, isLoading, isFullScreen, isPageLoader, height, className }: Props) => {
  return (
    <div className={cx(styles.loader, className, { [styles.fullScreen]: isFullScreen })} style={{ height: height }}>
      {isLoading &&
        (isPageLoader ? (
          <div className={styles.loaderIcon}>
            <ReactSVG
              src={marsLogo}
              className={cx(styles.marsLoader, styles.shine, isFullScreen && styles.fullPageAnimation)}
            />
            <ReactSVG src={marsLogo} className={cx(styles.marsLoader, isFullScreen && styles.fullPageAnimation)} />
          </div>
        ) : (
          <>
            <div className={styles.loaderIcon}>
              <ReactSVG src={circleLoader} className={cx(styles.centeredIcon, styles.loading)} />
            </div>
          </>
        ))}
      <div className={cx({ [styles.loaderChildren]: isLoading })}>{children}</div>
    </div>
  );
};

export default Loader;
