import React from 'react';
import AppRouter from './config/Router/AppRouter';
import { init } from '@multibase/js';

init('bcb60aed-561b-45b6-970c-54f2dc498dfa');

const AppProviders = React.lazy(() => import('config/Router/AppProviders'));
const LayoutRouter = React.lazy(() => import('config/Router/LayoutRouter'));

const App = () => (
  <AppRouter>
    <AppProviders>
      <LayoutRouter />
    </AppProviders>
  </AppRouter>
);

export default App;
