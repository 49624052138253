import React, { ReactNode, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Loader from 'common/Loader/Loader';
import ScrollToTop from 'common/ScrollToTop/ScrollToTop';

export const AppRouter = ({ children }: { children: ReactNode }) => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Suspense fallback={<Loader isLoading isFullScreen isPageLoader />}>{children}</Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
